// src/services/search.service.js
// This file uses ES6 modules (ESM)

import { axiosService } from "@/services/apiService/axiosService";
import { aventureApi } from "@/services/apiService/aventureApi";
import { searchActions } from "@/store/search/search.slice";

const searchApi = aventureApi.injectEndpoints({
  endpoints: (builder) => ({
    getSearchData: builder.query({
      async queryFn(incomeData, { dispatch }) {
        const { search, action } = incomeData;
        try {
          dispatch(searchActions.resetSearch());
          const { data } = await axiosService.get(`res/search/${search}`, {});
          if (data?.success) {
            dispatch(
              searchActions.setResultCompanies(
                data?.res?.filter((el) => el?.typeRecord === "Company")
              )
            );
            dispatch(searchActions.setResultPersons(data?.person));
            dispatch(
              searchActions.setResultFunds([
                ...data?.res?.filter((el) => el?.typeRecord !== "Company"),
                ...data?.fund,
              ])
            );
            dispatch(searchActions.setResultTags(data?.tags));
            if (action) action();
          }
          return { data };
        } catch (error) {
          return { error: error?.message || error };
        }
      },
      providesTags: ["search"],
    }),
  }),
  overrideExisting: true,
});

export const { useLazyGetSearchDataQuery } = searchApi;

export default searchApi;
